body {background:#F5F5F5;}
.adminpanel .sidebar h2 {color:#007082;font-size:26px;font-weight:400;margin: 0 auto;margin-top:16px;text-align:center;}
.adminpanel .logo img {width:88px;}
.adminpanel .logo {padding-top:17px;margin: 0 auto;text-align:center;}
.adminpanel .sidebar {background:white;width:233px;height:100vh;}
.adminpanel .main {display:flex;}
.adminpanel .main h1 {font-size:18px;padding-bottom:22px;padding-top:100px;}
.adminpanel .marea {width: calc(100% - 233px);text-align:right;direction:rtl;}
.adminpanel .marea .contents_head {display:flex;justify-content:space-between;max-width:1423px;}
.adminpanel .marea .contents {padding-right:140px;}
.adminpanel .marea .contents a {color: black;}
.adminpanel .marea .contents a:hover {text-decoration: none;}
.adminpanel .marea .contents .icon_removecategory {width:20px;margin-left:15px;cursor:pointer;}
.adminpanel .marea .contents i.del {width:15px;height:15px;}
.adminpanel .marea .contents i.cat {margin: 0 0 0 15px;}
.adminpanel .marea .contents i.cat img {margin-top: 2px;}
.adminpanel .marea table {width: 1423px;border-radius: 15px;}
.adminpanel .marea table thead {background:#74CCCC;}
.adminpanel .marea table thead th {color:white;height:50px;text-indent:30px;}
.adminpanel .marea table tbody td {height: 55px;color:#000000;font-size:16px;text-indent:30px;border-bottom:solid 1px #ddd;}
.adminpanel .marea table tbody td .save,
.adminpanel .importResponse {margin-right: 30px;margin-top: 25px;}
.adminpanel .demo_file {color: black;}
.adminpanel .demo_file:hover {text-decoration: none;}
.adminpanel .importCSVFile {display:none;}
.adminpanel .hide {display:none;}
.adminpanel .importCSV {
  background:#74CCCC;
  color: white;
  border: 0;
  padding: 8px 15px;
  font-size:16px;
  margin-right: 30px;
  cursor:pointer;
}
.adminpanel .marea table tbody td .edit {
  cursor: pointer;
}
.loginFormFeedback {color: red;}
.adminpanel .macros li {margin-bottom: 5px;width:fit-content;}
.adminpanel .macros li:hover {text-decoration: underline;cursor: pointer;}
.adminpanel .macros {padding-right: 30px;}
.adminpanel .marea table tbody td .emptyicon {margin-right: 20px;cursor: auto;opacity:0.2;}
.adminpanel .marea table tbody td .setdefault,
.adminpanel .marea table tbody td .del {margin-right: 20px;cursor: pointer;}
.adminpanel .marea table tbody tr:hover {background:rgba(116, 204, 204, 0.4);}
.adminpanel .marea table thead th:first-child {border-radius: 0 15px 0 0;}
.adminpanel .marea table thead th:last-child {border-radius: 15px 0 0 0;}
.adminpanel .sidebar hr {background:#E0E0E0;padding:0;margin:0;font-size:1px;line-height:1px;border:0;height:1px;margin-top:19px;}
.adminpanel .sidebar ul {padding:0;margin:0;list-style:none;direction:rtl;text-align:right;font-size:16px;margin-top:33px;padding-right:54px;}
.adminpanel .sidebar ul li a {padding-bottom:18px;display:flex;cursor: pointer;text-decoration: none;color:#000;}
.adminpanel .sidebar ul li.tagged a {text-decoration: underline;}
.adminpanel .sidebar ul li img {padding-left:14px;}
.adminpanel .emptyIcon {width:15px;display: inline-block;}
.adminpanel .icon_create {cursor: pointer;}
.w-30 {width: 30%;}
.w-75 {width: 75%;}
.w-120 {width: 120px;}
.feedback.err {color: red;padding-bottom: 25px;}
.adminpanel .marea .longInput {width:950px;}
.adminpanel .marea input {width: 200px;height: 30px;font-size:16px}
.adminpanel .marea .w-120 input {width:120px;}
.loginFrame {
  width: 480px;
  height: 574px;
  background:#F5F5F5;
  border-radius: 0 75px 0 75px;
  margin: 0 auto;
  text-align: center;
  margin-top: 25px;
}
.loginFrame.logo {
  padding-top: 70px;
  max-width: 75%;
}
.loginFrame h1 {color:#00939D;font-size:40px;}
.loginFrame fieldset {border:0;}
.loginFrame fieldset input {background:white;border:0;width:315px;height:40px;text-align:right;direction:rtl;font-size:20px;text-indent:10px;}
.loginFrame button {background:#2CBFC9;color:white;width:180px;height:40px;border:0;font-size:25px;margin-top:40px;border-radius:25px;line-height:25px;}